<template>
  <div class="w-screen">
    <!-- <NavBar title="电商融" :icon="icon" /> -->
    <div class="w-screen bg-blue-500 h-[40vh]">
      <p class="w-full absolute top-[10vh] text-xl text-[#fff] text-center">自主核额工具</p>
    </div>
    <div class="w-[90%] bg-[#fff] p-10 pt-5 absolute left-[50%] top-[30vh] translate-x-[-50%] rounded-2xl shadow-2xl">
      <div class="w-[100%] m-auto mt-4 input">
        <input type="tel" class="appearance-none border-0 p-1 focus:outline-none focus:border-0 text-sm"
          placeholder="请输入手机号" @input="phoneInput" @blur="phoneBlur" v-model="userMobile" />
        <div class="phone-tip" ref="phoneTipRef">您输入的手机号码有误</div>
        <hr class="mt-2 b-2" />
        <div class="flex justify-between mt-6">
          <input type="tel" class="appearance-none border-0 p-2 focus:outline-none focus:border-0 text-sm w-[50%]"
            placeholder="请输入验证码" @input="inputyanzm" v-model="yanzm" />
          <p v-if="!showTime" class="text-[#4095E5] mt-2 text-sm" @click="getYzm" style="cursor: pointer;">获取验证码</p>
          <p v-else class="text-[#CECECE] mt-2">{{ yanzma }}</p>
        </div>
        <hr class="mt-2 b-2" />

        <div class="flex items-center mt-10">
          <!-- <CircleCheck style="width: 1em; height: 1em; margin-right: 8px; color: #4095e5" /> -->
          <el-checkbox v-model="checked1" class="checkbox" style="margin-right: 8px" />
          <p class="text-sm" style="cursor: pointer;">我已同意签署<span class="text-[#4095E5] text-sm" @click="xieyi">《用户授权书》</span></p>
        </div>
        <button class="rounded-[30px] w-[80%] h-[3rem] bg-[#0581FE] m-auto block mt-10 text-[#fff] text-sm"
          @click="login">登录</button>
      </div>
    </div>
    <div v-if="showMessageBox"
      class="w-[40vw] p-2 absolute bg-[#fff] left-[50%] top-[40vh] translate-x-[-50%] rounded-xl border-[#C0C0C0] border-2">
      <h2 class="text-lg text-center">用户协议及隐私保护</h2>
      <p class="text-xs w-[90%] m-auto">为了让你在安全，放心的情况下使用。请阅读并同意 <span class="text-[#4095E5] text-sm">《xxx用户协议》</span></p>
      <hr class="mt-2 b-2 border-2 w-[90%] m-auto mb-2" />
      <h2 class="text-lg text-center text-[#0075FF]" @click="agree(0)">同意并继续</h2>
      <hr class="mt-2 b-2 border-2 w-[90%] m-auto mb-2" />
      <h2 class="text-lg text-center text-[#0075FF]" @click="agree(1)">不同意</h2>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter} from 'vue-router'
import NavBar from '@/components/NavBar/NavBar.vue'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router';



const icon = require('../assets/riLine-close-line.svg')
const phoneTipRef = ref(null)
const router = useRouter()
const route=useRoute()

let num = ref(59)
let timer = ref(null)
let yanzma = ref()
let showTime = ref(false)
let userMobile = ref(localStorage.getItem('mobile') || '')
let yanzm = ref(localStorage.getItem('yanzm') || '')
let checked1 = ref(false)
let showMessageBox = ref(false)
let getYzma = ref(true)
let idValue=ref()

var urlParams = new URLSearchParams(window.location.search);
const channelId2 =(route.query.channelId)
if(channelId2==null){
  // 从搜索参数对象中提取'id'参数的值
   idValue.value = urlParams.get('id');
  
}else if(channelId2!=null){
  idValue.value=channelId2;
}

// 清除本地存储中的'mobile'和'yanzm'
localStorage.setItem('mobile', '')
localStorage.setItem('yanzm', '')

// 定义一个函数xieyi，将'mobile'和'yanzm'存入本地存储，并跳转到'/Xieyi'页面
const xieyi = () => {
  localStorage.setItem('mobile', userMobile.value)
  localStorage.setItem('yanzm', yanzm.value)
  router.push('/Xieyi'

  )
}

const getYzm = () => {
  // 如果showTime的值为true，则返回
  if (showTime.value) {
    return
  }

  // 打印userMobile的值
  console.log(userMobile.value)

  // 定义正则表达式
  const reg = /^1[0-9]{10}$/
  // 如果userMobile的值为空
  if (!userMobile.value) {
    // 提示错误信息
    ElMessage.error('请输入手机号')
    // 打印userMobile的值
    console.log(userMobile.value);
    // 返回
    return;
  }

  // 如果userMobile的值不满足正则表达式
  if (!reg.test(userMobile.value)) {
    // 提示错误信息
    ElMessage.error('请输入正确的手机号')
    // 返回
    return;
  }

  

  // 如果获取验证码的按钮被点击
  if (getYzma.value) {
    // 显示倒计时
    showTime.value = true;
    // 修改验证码按钮的文本
    yanzma.value = `60秒后重试`;
    // 禁用获取验证码按钮
    getYzma.value = false;

    // 重置倒计时的秒数为初始值
    num.value = 60;
    // 清除之前的计时器，确保只有一个计时器在工作
    clearInterval(timer.value);

    // 创建一个新的计时器
    timer.value = setInterval(() => {
      // 如果倒计时结束
      if (num.value <= 0) {
        // 清除计时器
        clearInterval(timer.value);
        // 隐藏倒计时
        showTime.value = false;
        // 启用获取验证码按钮
        getYzma.value = true;
      } else {
        // 显示倒计时
        showTime.value = true;
        // 修改验证码按钮的文本
        yanzma.value = `${num.value}秒后重试`;
        // 减少倒计时的秒数
        num.value--;
      }
    }, 1000)


    // 构造请求参数
    const params = new URLSearchParams();
    params.append('phoneNumber', userMobile.value);


    // 构造请求头
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('tenant-id', '1');

    // 发送请求
    fetch('/admin-api/incoming/credit-calculation/getVerificationCode', {
      method: 'POST',
      headers: headers,
      body: params
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);

      });
  }


}

// 手机号输入框失去焦点时，校验手机号是否合法
const phoneBlur = () => {
  const reg = /^1[0-9]{10}$/
  if (!reg.test(userMobile.value)) {
    phoneTipRef.value.style.display = 'block'
  } else {
    phoneTipRef.value.style.display = 'none'
  }
}

// 手机号输入框输入时，过滤掉非数字字符
const phoneInput = () => {
  console.log('userMobile.value', userMobile.value)
  userMobile.value = userMobile.value.replace(/[^0-9]/g, "")
  const reg = /^[0-9]{10}$/
  if (reg.test(userMobile.value)) {
    phoneTipRef.value.style.display = 'none'
  }
}

// 验证码输入框输入时，过滤掉非数字字符
const inputyanzm = () => {
  yanzm.value = yanzm.value.replace(/[^0-9]/g, "")
}

// 点击协议时，切换协议勾选状态
const agree = (num) => {
  if (num == 0) {
    checked1.value = true
    showMessageBox.value = false
  } else {
    showMessageBox.value = false
  }
}

const login = () => {
  const reg = /^1[0-9]{10}$/
  // 如果用户手机号没有输入
  if (!userMobile.value) {
    // 提示用户输入手机号
    ElMessage.error('请输入手机号')
    console.log(userMobile.value);
    return;
  }
  // 如果用户手机号输入不正确
  if (!reg.test(userMobile.value)) {
    // 提示用户输入正确的手机号
    ElMessage.error('请输入正确的手机号')
    return;
  }
  // 如果用户验证码没有输入
  if (!yanzm.value) {
    // 提示用户输入验证码
    ElMessage.error('请输入验证码')
    return;
  }
  // 如果用户没有同意用户协议
  if (!checked1.value && userMobile.value) {
    // 提示用户阅读并同意用户协议
    ElMessage.error('请阅读并同意用户协议')
    return

    // showMessageBox.value = true
  } else {
    // 构造请求参数
    const params = new URLSearchParams();
    params.append('phoneNumber', userMobile.value);
    params.append('code', yanzm.value);
    console.log(idValue.value)
    if(idValue.value!==null){
      params.append('channelId', (idValue.value));
    }else if(idValue.value===null){
      params.append('channelId', 1);
    }
   
  
    // // 构造请求头
    const headers = new Headers();
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('tenant-id', '1');

    // 发送请求
    fetch('/admin-api/incoming/credit-calculation/login', {
      method: 'POST',
      headers: headers,
      body: params
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 400) {     
          ElMessage.error('请输入正确的验证码')
        } else {
          router.push({
            path: '/storeInfo',
            query: { 
              channelId:idValue.value || '1',
              userMobile:userMobile.value
            }
          })
        }

      })
      .catch(error => {
        console.error('Error:', error);
        // 在这里处理错误
      });

  }

}
</script>

<style lang="scss" scoped>
.input {
  position: relative;

  .phone-tip {
    position: absolute; // 设置元素绝对定位
    color: #ff0000; // 设置文字颜色为红色
    font-size: 12px; // 设置文字大小为12px
    display: none; // 设置元素隐藏
    top: 2.5rem; // 设置元素距离顶部2.5rem
  }
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  //选中
  // 设置边框颜色
  border-color: 1px solid #3b82f6 !important;
  // 设置背景颜色
  background-color: transparent;
  // 设置边框半径
  border-radius: 50% !important;
  // 设置宽度
  width: 1rem;
  // 设置高度
  height: 1rem;

  &::after {
    // 里面的对钩
    /* 设置边框颜色 */
    border-color: #3b82f6;
    /* 设置显示方式 */
    display: flex;
    /* 设置对齐方式 */
    align-items: center;
    /* 设置两端对齐 */
    justify-content: center;
    /* 设置高度 */
    height: 7px;
    /* 设置宽度 */
    width: 5px;
  }
}

:deep(.el-checkbox__inner) {
  //大小
  // 设置边框颜色
  border-color: 1px solid #3b82f6 !important;
  // 设置背景颜色
  background-color: transparent;
  // 设置边框半径
  border-radius: 50% !important;
  // 设置宽度
  width: 1rem;
  // 设置高度
  height: 1rem;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
  //对钩的位置
  top: 2px;
  left: 4px;
}</style>